.padding-20{
  padding:20px
}
.center{
  text-align: center;
}
.login-container{
  margin-top:50px
}
.logo{
  width:20%;
}
.item-image{
  width:70%;
  margin:3%;
  margin-left: 15%;
  height:100px;
  border-radius: 5px;
}
.logoutButton{
  position:absolute!important;
  width:100px!important;
  height:32px!important;
  z-index:100;
  right:10px!important;
  top:10px;

}
#notificationsContainer{
  position:absolute;
  top:13px;
  right:120px;
}
#notificationCount{
  position:absolute;
  width:15px;
  height:15px;
  border-radius: 6px;
  text-align: center;
  font-size:12px;
  top:-5px;
  right:-5px;
  background-color: red;
}
.orderIndex{
  position:absolute;
  width:50px;
  height:50px;
  top:0px;
  left:0px;
  text-align: center;
}